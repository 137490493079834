import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ContentContainer from "../components/contentContainer"
import FluidContainer from "../components/fluidContainer"
import styled from "@emotion/styled"
import { Header2, Header1, Paragraph } from "../components/headings"
import ReactMarkdown from "react-markdown"

const ListItem = styled.li`
  list-style: none;
  padding: 2rem;
  color: #333;
  a {
    text-decoration: none;
  }
  border-bottom: 1px dashed gray;
`

const ListContainer = styled.ul`
  background-color: #eaecec;
  margin: 0;
`

const ArticleList = ({ pageContext }) => {
  const [articles, setArticles] = useState(pageContext.articles)
  return (
    <Layout>
      <FluidContainer>
        <ContentContainer>
          <ListContainer>
            <Header1
              style={{ color: "#333", padding: "2rem", textAlign: "center" }}
            >
              News archives
            </Header1>
            {articles.map(art => {
              return (
                <ListItem>
                  <Link to={"/articles/" + art.node.sn}>
                    <Header2>{art.node.title}</Header2>
                  </Link>
                  <Paragraph>{art.node.createdAt}</Paragraph>
                  <Paragraph>
                    <ReactMarkdown
                      source={art.node.content.substring(0, 150) + "..."}
                      escapeHtml={false}
                    />
                  </Paragraph>
                </ListItem>
              )
            })}
          </ListContainer>
        </ContentContainer>
      </FluidContainer>
    </Layout>
  )
}

export default ArticleList
